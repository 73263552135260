import { useEffect, useState } from 'react';

import { useAuth } from 'hooks';
import { actions } from 'modules/home/_redux/scanRedux';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Faq from '../faq';
import { MenuIcon, User } from '../icons';
import Marquee from '../marquee';
import MobileDropdown from '../mobile-dropdown';
import MobileMenu from '../mobile-menu';
import RenderIf from '../RenderIf';
import ScanInput from '../scan-input';
import BalanceInfoModal from './BalanceInfoModal';
import ChangePasswordModal from './ChangePassword';
import FaqModal from './FaqModal';
import GameList from './GameList';
import NavLinkDropdown from './NavLinkDropdown';
import PrintModal from './PrintModal';
import WithdrawModal from './WithdrawModal';

import './Header.css';
import TopupBalanceModal from './TopupBalanceModal';

const Header = ({ isHome }) => {
  const isUserLoggedIn = useAuth();

  const dispatch = useDispatch();

  const { isScanner, inputValue, time } = useSelector((state) => state.scan);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showFaq, setShowFaq] = useState(false);
  const [showBalanceInfo, setShowBalanceInfo] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showTopupBalanceModal, setShowTopupBalanceModal] = useState(false);
  const [printData, setPrintData] = useState({});
  const [voucherAmount, setVoucherAmount] = useState(0);

  useEffect(() => {
    const handleBodyClick = (e) => {
      const value = e.key;
      const regex = /^\d+$/;
      if (regex.test(value)) dispatch(actions.setInput(value));
    };

    document.body.addEventListener('keydown', handleBodyClick);

    return () => {
      document.body.removeEventListener('keydown', handleBodyClick);
    };
  }, []);

  useEffect(() => {
    if (inputValue) {
      const interval = setInterval(() => {
        dispatch(actions.addTime(1));
      }, 1000);

      if (time >= 2) {
        clearInterval(interval);
        dispatch(actions.resetScanner());
      }

      if (inputValue.length === 16 && time <= 2) {
        dispatch(actions.setIsScanner(true));
      }

      return () => {
        clearInterval(interval);
      };
    }
  }, [inputValue, time, isScanner]);

  return (
    <>
      <header className={`header ${isHome ? 'position-absolute' : ''}`}>
        <div className="header__container h-100 px-2 px-sm-4 py-2 py-lg-2">
          <button
            onClick={() => setIsMenuOpen(true)}
            className="header__mobile-menu"
          >
            <MenuIcon />
          </button>
          <Link to="/" className="header__logo">
            <img src="/media/topaz-nard-logo-red.svg" alt="Topaz Nard" />
          </Link>
          <GameList className="d-lg-flex d-none" />
          <NavLinkDropdown className="d-lg-flex d-none" />
          <div className="d-flex align-items-center ms-auto header__buttons-right">
            <RenderIf condition={isUserLoggedIn}>
              <ScanInput
                setShowBalanceInfo={setShowBalanceInfo}
                setVoucherAmount={setVoucherAmount}
                className="ms-auto me-3 d-none d-lg-flex"
              />
            </RenderIf>
            <Faq
              className={`me-3 header__faq d-none d-lg-flex`}
              setShowFaq={setShowFaq}
            />
            {isUserLoggedIn ? (
              <>
                <MobileDropdown
                  setShowWithdraw={setShowWithdraw}
                  setShowChangePassword={setShowChangePassword}
                  setShowTopupBalanceModal={setShowTopupBalanceModal}
                />
              </>
            ) : (
              <>
                <Link
                  to="/auth/register"
                  className="header__register-button me-3"
                >
                  Qeydiyyat
                </Link>
                <Link to="/auth/login" className="header__login-button">
                  <User /> Daxil ol
                </Link>
              </>
            )}
          </div>
        </div>
        <Marquee />
        <GameList className="d-lg-none d-flex" />
        <MobileMenu
          open={isMenuOpen}
          onHide={setIsMenuOpen}
          setShowFaq={setShowFaq}
        />
        <RenderIf condition={showChangePassword}>
          <ChangePasswordModal
            show={showChangePassword}
            onHide={setShowChangePassword}
          />
        </RenderIf>
        <RenderIf condition={showFaq}>
          <FaqModal show={showFaq} onHide={setShowFaq} />
        </RenderIf>
        <RenderIf condition={showBalanceInfo}>
          <BalanceInfoModal
            show={showBalanceInfo}
            onHide={setShowBalanceInfo}
            voucherAmount={voucherAmount}
          />
        </RenderIf>
        <RenderIf condition={showWithdraw}>
          <WithdrawModal
            show={showWithdraw}
            onHide={setShowWithdraw}
            setShowPrintModal={setShowPrintModal}
            setPrintData={setPrintData}
          />
        </RenderIf>
        <RenderIf condition={showTopupBalanceModal}>
          <TopupBalanceModal
            show={showTopupBalanceModal}
            onHide={setShowTopupBalanceModal}
            setShowBalanceInfo={setShowBalanceInfo}
            setVoucherAmount={setVoucherAmount}
          />
        </RenderIf>
        <RenderIf condition={showPrintModal}>
          <PrintModal
            show={showPrintModal}
            onHide={setShowPrintModal}
            printData={printData}
          />
        </RenderIf>
      </header>
    </>
  );
};

export default Header;
