const actionTypes = {
    UpdateTimer: "[Update timer] action",
    UpdateInputValue: "[Update input value] action",
    ResetState: "[Reset state] action",
    SetIsScanner: "[Set is scanner] action",
    SetRandomId: "[Set random ID] action"
};

const initialState = {
    time: 0,
    inputValue: "",
    isScanner: false,
    id: null
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UpdateTimer: {
            const {time} = action.payload;
            return {
                ...state, time: state.time + time
            }
        }

        case actionTypes.UpdateInputValue: {
            const {inputValue} = action.payload;
            return {
                ...state, inputValue: state.inputValue.length === 16 ? inputValue : state.inputValue + inputValue
            }
        }

        case actionTypes.ResetState: {
            return {...initialState, id: state.id}
        }

        case actionTypes.SetIsScanner: {
            const {isScanner} = action.payload;
            return {
                ...state, isScanner
            }
        }

        case actionTypes.SetRandomId: {
            const {id} = action.payload;
            return {
                ...state, id
            }
        }

        default:
            return state

    }
}

export const actions = {
    addTime: (time) => ({
        type: actionTypes.UpdateTimer,
        payload: {time}
    }),
    setInput: (inputValue) => ({
        type: actionTypes.UpdateInputValue,
        payload: {inputValue}
    }),
    resetScanner: () => ({
        type: actionTypes.ResetState
    }),
    setIsScanner: (isScanner) => ({
        type: actionTypes.SetIsScanner,
        payload: {isScanner}
    }),
    setRandomId: (id) => ({
        type: actionTypes.SetRandomId,
        payload: {id}
    })
}