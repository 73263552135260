import axios from 'axios';

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}customers/login`;
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}customers/register`;

const CHECK_OTP_URL = `${process.env.REACT_APP_API_URL}customers/validateOtp`;
export const CHECK_REGISTER_URL = `${process.env.REACT_APP_API_URL}customers/confirm`;
export const ME_URL = `${process.env.REACT_APP_API_URL}customers/profile`;
export const CHANGE_PASSWORD = `${process.env.REACT_APP_API_URL}customers/changePassword`;
export const OTP = `${process.env.REACT_APP_API_URL}customers/sendOtp`;
export const REFRESH_TOKEN = `${process.env.REACT_APP_API_URL}auth/refresh`;

export const CHANGE_PROFILE_INFO = `${process.env.REACT_APP_API_URL}customers/changeProfileOneTime`;

const HAS_ATTEMPTED = `${process.env.REACT_APP_API_URL}customers/hasAttempted`;

export function login(phoneNumber, password) {
    return axios.post(LOGIN_URL, {phoneNumber, password});
}

export function register(body) {
    return axios.post(REGISTER_URL, body);
}

export function checkOtp(body) {
    return axios.post(CHECK_OTP_URL, body);
}

export function checkRegister(body) {
    return axios.put(CHECK_REGISTER_URL, body);
}

export function getPhoneNumberByToken() {
    return axios.get(ME_URL);
}

export function otp(number) {
    return axios.post(OTP, {number});
}

export function changePassword(otp, phoneNumber, password) {
    return axios.put(CHANGE_PASSWORD, {otp, phoneNumber, password});
}

export function getRefreshToken(refreshToken) {
    return axios.post(REFRESH_TOKEN, {refreshToken});
}

export function updateBirthDate(body) {
    return axios.put(CHANGE_PROFILE_INFO, body)
}

export function hasAttempted(number) {
    return axios.get(`${HAS_ATTEMPTED}?number=${number}`)
}
