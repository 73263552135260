import React, {Fragment, useEffect, useState} from "react";

import InfoIcon from "@mui/icons-material/Info";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination
} from '@mui/material';
import DateTimePicker from "components/DatePicker";
import {Calendar, CalendarCheck} from "components/icons";
import RenderIf from "components/RenderIf";
import {format} from "date-fns";
import dayjs from "dayjs";
import Alert from "react-bootstrap/Alert";
import {Helmet} from "react-helmet-async";
import {createUseStyles} from 'react-jss';


import {getRead} from "./requests";
import "./BalanceHistory.css";
import {paramsObjectToString} from "../../helpers";

const useStyles = createUseStyles({
    tableContainer: {
        background: "#2b2b2b !important",
        borderRadius: "18px",
    },
    table: {
        background: "#2b2b2b",
        borderRadius: "18px",
    },
    tableRow: {
        "&:nth-child(2n+1)": {
            background: "#1e1e1e"
        }
    },
    tableHeadCell: {
        color: "#7B7B7B",
        borderBottom: "unset",
        cursor: "pointer",
        whiteSpace: "nowrap"
    },
    tableCell: {
        color: "#FFFFFF",
        borderBottom: "unset",
        padding: "12px 16px"
    },
    mobileCard: {
        background: "#2b2b2b",
        padding: "24px 12px",
        borderRadius: "24px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    },
    mobileCardTitle: {
        display: "flex",
        justifyContent: "space-between",
        "& span:first-child": {
            color: "#7B7575",
        },
        "& span:last-child": {
            color: "#FFFFFF"
        }
    },
    mobileCardDates: {
        display: "flex",
        justifyContent: "space-between",
        color: "#A8A8A8",
        fontSize: "14px"
    },
    alert: {
        background: "#7B7B7B",
        borderColor: "#7B7B7B",
        color: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        gap: "12px"
    }
});

const headCells = [
    {
        id: "createdDateTime",
        label: "Yaradılma tarixi"
    },
    {
        id: "useDate",
        label: "Oxudulma tarixi"
    },
    {
        id: "voucherNumber",
        label: "Vauçer kod"
    },
    {
        id: "price",
        label: "Məbləğ"
    },
];

const dateObject = new Date();
const startDate = `${dateObject.getFullYear()}-${dateObject.getMonth()}-${dateObject.getDay() + 1}`
const endDateDate = `${dateObject.getFullYear()}-${dateObject.getMonth() + 1}-${dateObject.getDay() + 1}`


const BalanceHistory = () => {
    const [filter, setFilter] = useState({
        startDate,
        endDateDate,
        skip: 0,
        take: 10,
        sortField: "createdDateTime",
        orderBy: false
    });
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const classes = useStyles();

    const handleChangeFilter = (fieldName, value) => {
        setFilter(prevState => ({
            ...prevState, [fieldName]: value
        }))
    };

    const onChangePage = (e, page) => {
        const skip = filter.take * (page - 1);
        handleChangeFilter("skip", skip)
    };

    const handleSortField = (fieldName) => {
        if (filter.sortField === fieldName) {
            handleChangeFilter("orderBy", !filter.orderBy)
        } else {
            handleChangeFilter("sortField", fieldName);
            handleChangeFilter("orderBy", false);
        }
    }


    useEffect(() => {
        const query = paramsObjectToString(filter);
        setIsLoading(true);
        getRead(query)
            .then(res => {
                setData(res.data?.data ?? []);
                setTotalCount(res.data?.totalCount ?? 0);
                const pageCount = Math.ceil(res.data?.totalCount / filter.take);
                setPageCount(pageCount);
            })
            .catch(() => null)
            .finally(() => setIsLoading(false))
    }, [filter])

    return (
        <Fragment>
            <Helmet>
                <title>Oxudulan vauçerlər | Nard</title>
                <meta name="description" content="Turlar | 1x2"/>
            </Helmet>
            <div className="bet-history">
                <div className="bet-history__container px-2 px-sm-4">
                    <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center">
                        <div className="d-flex gap-2 flex-column">
                            <h1 className="bet-history__title">Oxudulan vauçerlər</h1>
                            <span className="bet-history__count">Vauçerlərin sayı: {totalCount}</span>
                        </div>
                        <div className="d-flex gap-2 mb-2">
                            <DateTimePicker
                                value={dayjs(filter.startDate)}
                                onChange={startDate => handleChangeFilter("startDate", startDate)}
                            />
                            <DateTimePicker
                                value={dayjs(filter.endDateDate)}
                                onChange={endDateDate => handleChangeFilter("endDate", endDateDate)}
                            />
                        </div>
                    </div>
                </div>
                <div className="bet-history__container px-2 px-sm-4">
                    <div className="d-flex flex-column gap-2 d-lg-none">
                        <RenderIf condition={data?.length && !isLoading}>
                            {data.map(item => (
                                <div key={item.id} className={classes.mobileCard}>
                                    <div className={classes.mobileCardTitle}>
                                        <span>{item.voucherNumber}</span>
                                        <span>{item.price} AZN</span>
                                    </div>
                                    <div className={classes.mobileCardDates}>
                                    <span><Calendar
                                        className="me-2"/>{item.createdDateTime && format(new Date(item.createdDateTime), "dd.MM.yyyy HH:mm:ss")}</span>
                                        <span><CalendarCheck
                                            className="me-2"/>{item.useDate && format(new Date(item.useDate), "dd.MM.yyyy HH:mm:ss")}</span>
                                    </div>
                                </div>
                            ))}
                        </RenderIf>
                    </div>
                    <RenderIf condition={data?.length && !isLoading}>
                        <TableContainer className={`${classes.tableContainer} d-none d-lg-block`}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        {headCells.map(item => (
                                            <TableCell
                                                key={item.id}
                                                onClick={() => handleSortField(item.id)}
                                                className={classes.tableHeadCell}>
                                                {item.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map(item => (
                                        <TableRow key={item.id} className={classes.tableRow}>
                                            <TableCell
                                                className={classes.tableCell}>{item.createdDateTime && format(new Date(item.createdDateTime), "dd.MM.yyyy HH:mm:ss")}</TableCell>
                                            <TableCell
                                                className={classes.tableCell}>{item.useDate && format(new Date(item.useDate), "dd.MM.yyyy HH:mm:ss")}</TableCell>
                                            <TableCell className={classes.tableCell}>{item.voucherNumber}</TableCell>
                                            <TableCell className={classes.tableCell}>{item.price} AZN</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </RenderIf>
                    <RenderIf condition={!data?.length && !isLoading}>
                        <div className="d-flex justify-content-center">
                            <Alert className={classes.alert}>
                                <InfoIcon/>
                                Seçilən tarixdə oxudulan vauçeriniz yoxdur.
                            </Alert>
                        </div>
                    </RenderIf>
                    <RenderIf condition={data?.length && !isLoading}>
                        <div className="mt-2 d-flex justify-content-center">
                            <Pagination
                                count={pageCount}
                                onChange={onChangePage}
                                color="primary"
                                className={classes.pagination}
                            />
                        </div>
                    </RenderIf>
                </div>
            </div>
        </Fragment>
    )
}

export default BalanceHistory;