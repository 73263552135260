const actionTypes = {
  SetGameId: '[Set Game Id] action',
};

const initialState = {
  gameId: 2,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetGameId: {
      const { gameId } = action.payload;
      return {
        ...state,
        gameId,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setGameId: (gameId) => ({
    type: actionTypes.SetGameId,
    payload: { gameId },
  }),
};
