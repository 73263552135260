import React, {useState, useEffect} from 'react';

import {CircularProgress} from "@mui/material";
import axios from 'axios';
import Rotate from "components/icons/Rotate";
import RenderIf from "components/RenderIf";
import {format} from "date-fns";
import {Modal, Button} from 'react-bootstrap';
import {createUseStyles} from 'react-jss'
import {useSelector} from 'react-redux';
import {Link, useNavigate} from "react-router-dom";
import {toast} from 'react-toastify';

import {findTicketBetType} from "../helpers";
import './styles/style.css';

const GoMyTickets = () => (
    <div>
        <h2 className="mb-2">Bron et kuponu uğurla yaradıldı!</h2>
        <Link to="/my-bron-tickets">"Bron edilmiş kuponlar"a keçid et.</Link>
    </div>
);

const ErrToast = () => (
    <div>
        <h2>Əməliyyat uğursuzdur.</h2>
    </div>
);

const useStyles = createUseStyles({
    table: {
        width: "100%",
        "& tbody tr:nth-child(odd)": {
            backgroundColor: "#EAF6FF"
        },
        "& tr td,th": {
            padding: "10px"
        }
    },
    won: {
        backgroundColor: "green",
        borderRadius: "4px",
        padding: "4px 8px",
        color: "#FFFFFF"
    },
    lost: {
        backgroundColor: "red",
        borderRadius: "4px",
        padding: "4px 8px",
        color: "#FFFFFF"
    },
    pending: {
        backgroundColor: "gray",
        borderRadius: "4px",
        padding: "4px 8px",
        color: "#FFFFFF"
    }
});

function ShowTicket({
                        show,
                        onHide,
                        ticketData,
                        circulationId,
                        canBePlayed,
                    }) {
    const {isAuth} = useSelector(({auth}) => ({
        isAuth: auth.token !== undefined,
    }));

    const classes = useStyles();

    const [allGames, setAllGames] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const betType = findTicketBetType(ticketData.ticketGames);

    const ticketGames = ticketData?.ticketGames?.map((ticket) => ({
        gameId: ticket.gameId,
        option1: ticket.options.option1,
        optionX: ticket.options.optionX,
        option2: ticket.options.option2,
    }));

    const getGameResultById = (gameId) => allGames.find(game => game.id === gameId)?.gameOptionId;

    const body = {
        ticketGames,
        circulationId,
    };

    const onSubmit = () => {
        if(!isAuth) {
           return navigate('/auth')
        }
        axios
            .post(`voidTickets/create`, body)
            .then(() => {
                toast.success(GoMyTickets);
                onHide(false);
            })
            .catch(() => {
                toast.error(ErrToast);
            })
    };

    async function getResults() {
        setIsLoading(true);
        try {
            const {data} = await axios({
                method: 'GET',
                url: `circulations/allGames`,
                params: {
                    id: circulationId,
                },
            });
            setAllGames(data.circulationGames);
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getResults();
    }, []);

    const gameResult = {
        0: "1",
        1: "X",
        2: "2",
        3: "Məlum deyil",
        4: <Rotate/>
    }

    const prediction = ({option1, option2, optionX}, optionId) => {
        if (option1 && !option2 && !optionX) {
            return <span
                className={optionId === 0 ? classes.won : optionId === 3 ? classes.pending : classes.lost}>1</span>
        } else if (option1 && optionX && !option2) {
            return <span
                className={optionId === 0 || optionId === 1 ? classes.won : optionId === 3 ? classes.pending : classes.lost}>1/X</span>
        } else if (option1 && !optionX && option2) {
            return <span
                className={optionId === 0 || optionId === 2 ? classes.won : optionId === 3 ? classes.pending : classes.lost}>1/2</span>
        } else if (!option1 && optionX && !option2) {
            return <span
                className={optionId === 1 ? classes.won : optionId === 3 ? classes.pending : classes.lost}>X</span>
        } else if (!option1 && optionX && option2) {
            return <span
                className={optionId === 1 || optionId === 2 ? classes.won : optionId === 3 ? classes.pending : classes.lost}>X/2</span>
        } else if (!option1 && !optionX && option2) {
            return <span
                className={optionId === 2 ? classes.won : optionId === 3 ? classes.pending : classes.lost}>2</span>
        } else if (option1 && optionX && option2) {
            return <span
                className={optionId === 0 || optionId === 1 || optionId === 2 ? classes.won : classes.pending}>1/X/2</span>
        }
    }


    return (
        <Modal show={show} onHide={() => onHide(false)}>
            <Modal.Header closeButton>
                <h1>Kupon məlumatları:</h1>
            </Modal.Header>
            <Modal.Body>
                {!isLoading ? (
                    <>
                        <div className="d-flex justify-content-between p-2">
                            <span>Tur nömrəsi:</span>
                            <span>{ticketData.circulationNumber}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                            <span>Oyunçu ID:</span>
                            <span>{ticketData.customerNumber}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                            <span>Yaradılma tarixi:</span>
                            <span>{ticketData.createdDate && format(new Date(ticketData.createdDate), "dd.MM.yyyy HH:mm:ss")}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                            <span>Mərc tipi:</span>
                            <span>{betType}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2 border-bottom border-1">
                            <span>Yekun mərc məbləği:</span>
                            <span>{`${ticketData.price} AZN`}</span>
                        </div>
                        <div className="col-12 pl-6 pr-6">
                            <table className={classes.table}>
                                <thead>
                                <tr>
                                    <th>Oyunlar</th>
                                    <th>Nəticələr</th>
                                    <th>Seçimlər</th>
                                </tr>
                                </thead>
                                <tbody>
                                {ticketData?.ticketGames?.map((ticket, index) => (
                                    <tr key={index}>
                                        <td>
                                            {ticket.hostClub} - {ticket.guestClub}
                                        </td>
                                        <td>{gameResult[getGameResultById(ticket.gameId)]}</td>
                                        <td className="d-flex justify-content-center">{prediction(ticket.options, getGameResultById(ticket.gameId))}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    <div className="w-100 d-flex justify-content-center">
                        <CircularProgress/>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className="w-100 d-flex justify-content-end align-items-center">
                    <RenderIf condition={canBePlayed}>
                        <Button variant="danger" onClick={onSubmit}>
                            {' '}
                            Bron et{' '}
                        </Button>
                    </RenderIf>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ShowTicket;
