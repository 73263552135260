import React from "react";

import {Close} from "components/icons";
import {Modal} from "react-bootstrap";
import {createUseStyles} from "react-jss";

import Accordion from "../accordion";

const useStyles = createUseStyles({
    title: {
        fontSize: "32px",
        lineHeight: "58px",
        fontWeight: 500,
        marginBottom: "24px"
    },
    closeButton: {
        width: "36px",
        height: "36px",
        border: "unset",
        borderRadius: "50%",
        position: "absolute",
        right: "30px",
        top: "20px",
        background: "#1E1E1E",

        "& svg": {
            stroke: "#FFFFFF"
        }
    },
    modalBody: {
        padding: "48px"
    }
});

const FaqModal = ({show, onHide}) => {
    const classes = useStyles();
    return (
        <Modal show={show} centered className="faq-modal-dialog">
            <Modal.Body className={`${classes.modalBody} faq-modal`}>
                <button type="button" onClick={() => onHide(false)} className={classes.closeButton}><Close/></button>
                <p className={classes.title}>Tez-tez verilən suallar</p>
                <Accordion/>
            </Modal.Body>
        </Modal>
    )
}

export default FaqModal;