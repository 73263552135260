import { Fragment, useEffect, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import DateTimePicker from 'components/DatePicker';
import PrintModal from 'components/header/PrintModal';
import { CalendarCheck, Scan } from 'components/icons';
import RenderIf from 'components/RenderIf';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import Alert from 'react-bootstrap/Alert';
import { Helmet } from 'react-helmet-async';
import { createUseStyles } from 'react-jss';

import { paramsObjectToString } from '../../helpers';
import { getWinningVouchers } from './requests';
import './WinHistory.css';

const useStyles = createUseStyles({
  tableContainer: {
    background: '#2b2b2b !important',
    borderRadius: '18px',
  },
  table: {
    background: '#2b2b2b',
    borderRadius: '18px',
  },
  tableRow: {
    '&:nth-child(2n+1)': {
      background: '#1e1e1e',
    },
  },
  tableHeadCell: {
    color: '#7B7B7B',
    borderBottom: 'unset',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  tableCell: {
    color: '#FFFFFF',
    borderBottom: 'unset',
    padding: '12px 16px',
  },
  mobileCard: {
    background: '#2b2b2b',
    padding: '15px 10px',
    borderRadius: '24px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  mobileCardDate: {
    color: '#A8A8A8',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  mobileCardVoucherTitle: {
    color: '#A8A8A8',
    fontSize: '10px',
    fontWeight: '400',
  },
  mobileCardVoucher: {
    color: '#A8A8A8',
    fontSize: '16px',
    fontWeight: 700,
  },
  mobileCardAmountTitle: {
    color: '#A8A8A8',
    fontSize: '10px',
    fontWeight: '400',
  },
  mobileCardAmount: {
    color: '#A8A8A8',
    fontSize: '16px',
    fontWeight: 700,
  },
  greenText: {
    color: '#16CA68',
  },
  buttons: {
    display: 'flex',
    gap: '8px',
  },
  button: {
    borderRadius: '62px',
    background: 'rgba(22, 202, 104, 0.10)',
    paddingInline: '12px',
    border: 'unset',
    width: '88px',
    height: '24px',
    color: '#16CA68',
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '13.033px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
  },
  scannedVoucherBadge: {
    borderRadius: '48px',
    background: 'rgba(0, 175, 245, 0.25)',
    width: '88px',
    height: '24px',
    color: '#00AFF5',
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '13.033px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  alert: {
    background: '#7B7B7B',
    borderColor: '#7B7B7B',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
});

const headCells = [
  {
    id: 'createdDateTime',
    label: 'Yaradılma tarixi',
  },
  {
    id: 'voucherNumber',
    label: 'Vauçer kod',
  },
  {
    id: 'price',
    label: 'Məbləğ',
  },
  {
    id: 'paidDate',
    label: 'Oxudulma tarixi',
  },
  {
    id: 'agentNumber',
    label: 'Kassir id',
  },
  {
    id: 'status',
    label: 'Status',
  },
];

const dateObject = new Date();
const startDate = `${dateObject.getFullYear()}-${dateObject.getMonth()}-${
  dateObject.getDay() + 1
}`;
const endDateDate = `${dateObject.getFullYear()}-${dateObject.getMonth() + 1}-${
  dateObject.getDay() + 1
}`;

const WinHistory = () => {
  const [filter, setFilter] = useState({
    startDate,
    endDateDate,
    skip: 0,
    take: 10,
    sortField: 'createdDateTime',
    orderBy: false,
  });
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [printData, setPrintData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const classes = useStyles();

  const handleChangeFilter = (fieldName, value) => {
    setFilter((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const onChangePage = (e, page) => {
    const skip = filter.take * (page - 1);
    handleChangeFilter('skip', skip);
  };

  const handleSortField = (fieldName) => {
    if (filter.sortField === fieldName) {
      handleChangeFilter('orderBy', !filter.orderBy);
    } else {
      handleChangeFilter('sortField', fieldName);
      handleChangeFilter('orderBy', false);
    }
  };

  const handleClickVoucher = (voucher) => {
    setShowPrintModal(true);
    setPrintData(voucher);
  };

  useEffect(() => {
    const query = paramsObjectToString(filter);
    setIsLoading(true);
    getWinningVouchers(query)
      .then((res) => {
        setData(res.data?.data ?? []);
        setTotalCount(res.data?.totalCount ?? 0);
        const pageCount = Math.ceil(res.data?.totalCount / filter.take);
        setPageCount(pageCount);
      })
      .catch(() => null)
      .finally(() => setIsLoading(false));
  }, [filter]);

  return (
    <Fragment>
      <Helmet>
        <title>Uduşlu vauçerlər | Nard</title>
        <meta name="description" content="Turlar | 1x2" />
      </Helmet>
      <div className="win-history">
        <div className="win-history__container px-2 px-sm-4">
          <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center">
            <div className="d-flex gap-2 flex-column">
              <h1 className="win-history__title">Uduşlu vauçerlər</h1>
              <span className="win-history__count">
                Vauçerlərin sayı: {totalCount}
              </span>
            </div>
            <div className="d-flex gap-2 mb-2">
              <DateTimePicker
                value={dayjs(filter.startDate)}
                onChange={(startDate) =>
                  handleChangeFilter('startDate', startDate)
                }
              />
              <DateTimePicker
                value={dayjs(filter.endDateDate)}
                onChange={(endDateDate) =>
                  handleChangeFilter('endDate', endDateDate)
                }
              />
            </div>
          </div>
        </div>
        <div className="win-history__container px-2 px-sm-4">
          <RenderIf condition={data?.length && !isLoading}>
            <div className="d-flex flex-column gap-2 d-lg-none">
              {data.map((item) => (
                <div
                  key={item.id}
                  className={classes.mobileCard}
                  onClick={() => handleClickVoucher(item)}
                >
                  <div className="d-flex justify-content-between">
                    <span className={classes.mobileCardDate}>
                      <CalendarCheck />{' '}
                      {item.createdDateTime
                        ? format(
                            new Date(item.createdDateTime),
                            'dd.MM.yyyy HH:mm:ss'
                          )
                        : ''}
                    </span>
                    {item.statusId === 1 ? (
                      <button className={classes.button}>
                        <Scan /> Barkod
                      </button>
                    ) : (
                      <div className={classes.scannedVoucherBadge}>
                        Oxudulub
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column gap-2">
                      <span className={classes.mobileCardVoucherTitle}>
                        Vauçer kod
                      </span>
                      <span className={classes.mobileCardVoucher}>
                        {item.voucherNumber}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-end gap-2">
                      <span
                        className={`${classes.mobileCardAmountTitle} ${
                          item.statusId === 1 ? classes.greenText : ''
                        }`}
                      >
                        Məbləğ
                      </span>
                      <span
                        className={`${classes.mobileCardAmount} ${
                          item.statusId === 1 ? classes.greenText : ''
                        }`}
                      >
                        {item.amount} AZN
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </RenderIf>
          <RenderIf condition={data?.length && !isLoading}>
            <TableContainer
              className={`${classes.tableContainer} d-none d-lg-block`}
            >
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headCells.map((item) => (
                      <TableCell
                        key={item.id}
                        onClick={() => handleSortField(item.id)}
                        className={classes.tableHeadCell}
                      >
                        {item.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item) => (
                    <TableRow key={item.id} className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        {item.createdDateTime &&
                          format(
                            new Date(item.createdDateTime),
                            'dd.MM.yyyy HH:mm:ss'
                          )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.voucherNumber}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.amount} AZN
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.paidDate &&
                          format(
                            new Date(item.paidDate),
                            'dd.MM.yyyy HH:mm:ss'
                          )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.agentNumber}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.statusId === 1 ? (
                          <div className={classes.buttons}>
                            <button
                              onClick={() => handleClickVoucher(item)}
                              className={classes.button}
                            >
                              <Scan /> Barkod
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={() => handleClickVoucher(item)}
                            className={classes.scannedVoucherBadge}
                          >
                            Oxudulub
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </RenderIf>
          <RenderIf condition={!data?.length && !isLoading}>
            <div className="d-flex justify-content-center">
              <Alert className={classes.alert}>
                <InfoIcon />
                Seçilən tarixdə oxudulan vauçeriniz yoxdur.
              </Alert>
            </div>
          </RenderIf>
          <RenderIf condition={data?.length && !isLoading}>
            <div className="mt-2 d-flex justify-content-center">
              <Pagination
                count={pageCount}
                onChange={onChangePage}
                color="primary"
                className={classes.pagination}
              />
            </div>
          </RenderIf>
        </div>
      </div>
      <RenderIf condition={showPrintModal}>
        <PrintModal
          show={showPrintModal}
          onHide={setShowPrintModal}
          printData={printData}
        />
      </RenderIf>
    </Fragment>
  );
};

export default WinHistory;
