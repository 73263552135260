import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {put, takeLatest} from 'redux-saga/effects';

import {getPhoneNumberByToken} from './authCrud';

export const actionTypes = {
    Login: '[Login] Action',
    Logout: '[Logout] Action',
    LogoutRequest: '[Request Logout] Action',
    Register: '[Register] Action',
    PhoneNumberRequested: '[Request PhoneNumber] Action',
    FulFillUser: '[FulFill User] Action',
    SetPhoneNumber: '[Set PhoneNumber] Action',
    RefreshToken: '[Refresh Token] Action',
    FulFillPhoneNumber: '[Fulfill Phone Number] Action',
    GetProfile: '[GET Profile]',
    SetOtp: '[Set OTP action]',
    TemporaryLogin: '[Temporary login action]'
};

const initialAuthState = {
    phoneNumber: undefined,
    token: undefined,
    refreshToken: undefined,
    expiredDate: undefined,
    checkToken: undefined,
    temporaryLogin: false
};

export const reducer = persistReducer(
    {
        storage,
        key: '1x2',
        whitelist: [
            'phoneNumber',
            'token',
            'refreshToken',
            'expiredDate',
            'id',
            'birthDate',
            'playerBalance',
            'temporaryLogin'
        ],
    },
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.Login: {
                const {token, refreshToken, expiredDate} = action.payload;
                return {
                    ...state,
                    token,
                    refreshToken,
                    expiredDate,
                };
            }

            case actionTypes.Register: {
                const {token} = action.payload;
                return {token};
            }

            case actionTypes.LogoutRequest: {
                return {...initialAuthState, temporaryLogin: state.temporaryLogin};
            }

            case actionTypes.FulFillUser: {
                return {...state, ...action.payload};
            }

            case actionTypes.FulFillPhoneNumber: {
                return {...state, phoneNumber: action.payload};
            }

            case actionTypes.SetPhoneNumber: {
                const {phoneNumber} = action.payload;
                return {...state, phoneNumber};
            }

            case actionTypes.RefreshToken: {
                const {token, expiredDate, refreshToken, checkToken} =
                    action.payload;

                return {
                    ...state,
                    token,
                    expiredDate,
                    refreshToken,
                    checkToken,
                };
            }

            case actionTypes.SetOtp: {
                const {otp} = action.payload;

                return {
                    ...state, otp
                }
            }

            case actionTypes.TemporaryLogin: {
                const {value} = action.payload;

                return {
                    ...state, temporaryLogin: value
                }
            }

            default:
                return state;
        }
    }
);

export const actions = {
    login: (token, refreshToken, expiredDate) => ({
        type: actionTypes.Login,
        payload: {token, refreshToken, expiredDate},
    }),
    register: (token) => ({
        type: actionTypes.Register,
        payload: {token},
    }),
    logout: () => ({
        type: actionTypes.Logout,
    }),
    requestLogout: () => ({
        type: actionTypes.LogoutRequest,
    }),
    requestPhoneNumber: (phoneNumber) => ({
        type: actionTypes.PhoneNumberRequested,
        payload: {phoneNumber},
    }),
    fulfillUser: (data) => ({
        type: actionTypes.FulFillUser,
        payload: data,
    }),
    fulfillPhoneNumber: (phoneNumber) => ({
        type: actionTypes.FulFillPhoneNumber,
        payload: phoneNumber,
    }),
    setPhoneNumber: (phoneNumber) => ({
        type: actionTypes.SetPhoneNumber,
        payload: {phoneNumber},
    }),
    setRefreshToken: (token, expiredDate, refreshToken) => ({
        type: actionTypes.RefreshToken,
        payload: {token, expiredDate, refreshToken},
    }),
    getProfile: () => ({
        type: actionTypes.GetProfile,
    }),
    setOtp: (otp) => ({
        type: actionTypes.SetOtp,
        payload: {otp}
    }),
    temporaryLogin: (value) => ({
        type: actionTypes.TemporaryLogin,
        payload: {value}
    })
};

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestPhoneNumber());
    });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestPhoneNumber());
    });

    yield takeLatest(
        actionTypes.PhoneNumberRequested,
        function* phoneNumberRequested() {
            const {data} = yield getPhoneNumberByToken();
            yield put(
                actions.fulfillUser({
                    phoneNumber: data?.phonenumber,
                    id: data?.id,
                })
            );
        }
    );
    yield takeLatest(actionTypes.GetProfile, function* profileRequested() {
        try {
            const {data} = yield getPhoneNumberByToken();
            yield put(
                actions.fulfillUser({
                    phoneNumber: data?.phonenumber,
                    birthDate: data?.birthDate,
                    id: data?.id,
                    playerBalance: data?.playerBalance
                })
            );
        } catch {
        }
    });

    yield takeLatest(actionTypes.Logout, function* logoutRequest() {
        yield put(actions.requestLogout());
    });
}
