import React from 'react';

import { Close } from 'components/icons';
import ScanInput from 'components/scan-input';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  modal: {
    '& .modal-content': {
      background: '#1e1e1e',
    },
  },
  title: {
    fontSize: '32px',
    textAlign: 'center',
    lineHeight: '42px',
    fontWeight: 500,
  },
  closeButton: {
    width: '36px',
    height: '36px',
    border: 'unset',
    background: 'unset',
    position: 'absolute',
    right: '20px',
    top: '10px',
    padding: 'unset',

    '& svg': {
      stroke: '#FFFFFF',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    marginBottom: '34px',
  },
  text: {
    maxWidth: '474px',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  inputContainer: {
    padding: '12px 16px',
    borderRadius: '10px',
    background: '#F5F5F5',
    '& svg': {
      marginRight: '8px',
    },
  },
  input: {
    border: 'unset',
    background: 'unset',
    outline: 'unset',
  },
  form: {
    display: 'flex',
    gap: '12px',
  },
  button: {
    background: '#00AFF5',
    width: '100%',
  },
});

export default function TopupBalanceModal({
  show,
  onHide,
  setShowBalanceInfo,
  setVoucherAmount,
}) {
  const classes = useStyles();

  function handleClose() {
    onHide(false);
  }

  return (
    <React.Fragment>
      <Modal centered show={show} onHide={onHide} className={classes.modal}>
        <Modal.Body className="faq-modal">
          <button
            type="button"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <Close />
          </button>

          <ScanInput
            setShowBalanceInfo={setShowBalanceInfo}
            setVoucherAmount={setVoucherAmount}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
