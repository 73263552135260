import { Line } from 'components/icons';
import { actions } from 'modules/home/_redux/gameRedux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './GameList.css';

const games = [
  {
    id: 1,
    image: './media/images/classic.png',
    name: 'Classic',
    url: 'classic',
  },
  {
    id: 2,
    image: './media/images/nard-36.png',
    name: 'Nard 36',
    url: 'nard-36',
  },
  // {
  //   id: 3,
  //   image: './media/images/sapper.svg',
  //   name: 'Sapper',
  //   url: 'sapper',
  // },
];

const GameList = ({ className = '' }) => {
  const { gameId } = useSelector((state) => state.game);

  const location = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search);

  const onClickGame = (id) => () => {
    const game = games.find((i) => i.id === id);
    navigate(`/?game=${game?.url}`);
  };

  useEffect(() => {
    if (location.pathname === '/' && !location.search) {
      navigate(`/?game=nard-36`);
    }
  }, [location]);

  useEffect(() => {
    const gameUrl = params.get('game');
    if (gameUrl) {
      const game = games.find((i) => i.url === gameUrl);
      dispatch(actions.setGameId(game?.id));
    }
  }, [location]);

  return (
    <div className={`game-list ${className}`}>
      {games.map((game) => (
        <div
          key={game.id}
          onClick={onClickGame(game.id)}
          className="d-flex align-items-center gap-2"
          role="button"
        >
          <img width="42" height="42" src={game.image} />
          <span
            className={`game-list__item ${
              game.url === params.get('game') && location.pathname === '/'
                ? 'game-list__item--active'
                : ''
            }`}
          >
            {game.name}
          </span>
          <Line />
        </div>
      ))}
    </div>
  );
};

export default GameList;
