import React, {useState} from "react";

import AccordionItem from "./AccordionItem";

import "./Accordion.css";

const faqData = [
    {
        id: 1,
        title: "Nərd oyunu üzrə mərc qaydaları",
        description: "1.Bu oyunun məqsədi, oyunda iştirak edən idmançılar (yarışçılar) tərəfindən atılan zərlərin dəyərini (üzərlərində 1-dən 6-ya qədər rəqəmlər olan mavi və qırmızı zərlər) təxmin etməkdir.\n" +
            "2.Yarış, klassik nərd qaydalarına uyğun olaraq keçirilir. İki idmançı, iki hissədən (sol və sağ) ibarət olan oyun lövhəsində zər atırlar. Hər bir idmançı fərqli rəngi olan 15 daşa malik olur. Hər oyunun başlanğıcında, daşlar hər bir idmançı üçün oyun lövhəsinin sağ tərəfində yerləşdirilir. Zərlərın sayı – 2 ədəddir, mavi və qırmızı.\n" +
            "3.İdmançılar növbə ilə zərləri atırlar. Hər iki zər, idmançı tərəfindən eyni zamanda zər atma qutusu (baffle box)*1 vasitəsilə atılır. Atış zamanı hər iki zərin zər atma qutusu səthində düz şəkildə durması lazımdır. Əgər zərlər zər atma qutusunun səthindən kənarda, bir-birinin üstündə və ya üfüqi olmayaraq qalarsa, zərlər yenidən atılır.\n" +
            "4.Atışdan sonra, idmançı öz daşlarından hər hansı birini, atılan zərlərın rəqəmsal dəyərinə uyğun olaraq lövhədəki müvafiq xanalara hərəkət etdirməlidir. İdmançı yalnız öz daşlarını hərəkət etdirə bilər. Əgər zərlər üzərində eyni rəqəmlər (ikiqat) çıxarsa, idmançının bu hərəkət daxilində yerinə yetirdiyi daşların hərəkəti ikiqat sayılır.\n" +
            "5.Yarışın məqsədi, bütün daşlarınızı \"ev\"-ə, yəni bütün daşlarınızı oyun lövhəsi ətrafında saat əqrəbinin əksi istiqamətdə tam dövr etdirib, \"ev\"-ə (oynama sahəsinin son dörddə bir hissəsində) yığmaq və sonra isə rəqibinizdən əvvəl oyun lövhəsindən çıxarmaqdır (daşları atmaq).\n" +
            "\n" +
            "\n" +
            "1* Zər atma qutusu (“Baffle box\") nərd və digər zər oyunlarında istifadə edilən, zərlərin atılması üçün xüsusi bir qutudur. Bu qutu, zərlərin tamamilə təsadüfi şəkildə qarışdırılmasını və hər atışda ədalətli və qərəzsiz nəticələr əldə edilməsini təmin etmək üçün dizayn edilmişdir.",
        open: false
    },
    {
        id: 2,
        title: "Necə qeydiyyatdan keçim?",
        description: "İlk öncə mobil nömrəni qeyd edib və doğum tarixi qeyd edib - dəvam et düyməsinə basmaq lazımdı. İkinci pəncərədə sizin nömrənizə gələn OTP kodu təsdiqləməlisiz. Üçüncü pəncərədə şifrəni və şifrənin təkrarını qeyd elədikdən sonra hesabınızı təsdiq edə bilərsiniz. Doğum tarixi 18 yaşdan aşağı olmamalıdır. OTP kod maksimum 3 dəfə sizə göndərilə bilər, limiti aşdığınız təqdirdə 926 nömrəsi ilə əlaqə saxlamağınız sizdən xaiş olunur.",
        open: false
    },
    {
        id: 3,
        title: "Vauçer nədir?",
        description: "Topaz məntəqələrindəki satıcıların pul vəsaiti məbləğini qəbul etdikdən sonra həmin məbləğ dəyərində təqdim etdiyi qəbzdir. Vauçer əldə etdikdən sonra topaznard.az saytlnda mərc etmək mümkündür.",
        open: false
    },
    {
        id: 4,
        title: "Vauçer kodu nədir?",
        description: "Vauçer kodu qəbzin üzərindəki 16 rəqəmli koddur. Bu 16 rəqəmli kodu daxil edərək balansınızı artıra bilərsiniz. Oyunçu vauçeri yaradıldığı müddətdən 3 gün ərzində istifadə edə bilər. Minimum vauçer məbləği 1 AZN, maksimum 1000 AZN ola bilər.",
        open: false
    },
    {
        id: 5,
        title: "Vauçeri necə əldə edə bilərəm və balansımı artıra bilərəm?",
        description: "İstənilən Topaz məntəqəsinə yaxınlaşaraq oynamaq istədiyiniz məbləği satıcıya təqdim edərək həmin məbləğdə vauçer əldə edə bilərsiniz. Vauçerin üzərindəki barkodu topaznard.az saytına daxil olub skan və ya kodu manual üsulla daxil etməklə balansınızı artıra bilərsiniz. Balansınız ancaq 0 olduğu halda yeni vauçeri istifadə edə bilərsiniz",
        open: false
    },
    {
        id: 6,
        title: "Mərc limitləri və maksimum ödənişlər",
        description: "Bir gediş üçün minimum mərc məbləği 0,10 ₼. <br/>Bir gediş üçün maksimum mərc məbləği 100 ₼.<br/>Bir gediş üçün hesablanmış maksimum qazanç 3000 ₼.<br/>Mərc məbləğləri: 0.10 AZN, 0.20 AZN, 0.50 AZN, 1 AZN, 5 AZN, 10 AZN, 20 AZN, 50 AZN, 100 AZN<br/>Sabit mərc qoyularkən balansda lazım olan vəsait yoxdursa, bütün mövcud balans sərf olunacaq.",
        open: false
    }
];

const Accordion = () => {
    const [data, setData] = useState(faqData);

    const handleClick = (index) => () => {
        setData(prevState => prevState.map(((item, idx) => {
            if (index === idx) {
                return {...item, open: !item.open}
            }
            return {...item, open: false}
        })))
    }

    return (
        <div className="accordion">
            {data.map((item, index) => (
                <AccordionItem
                    key={item.id}
                    title={item.title}
                    description={item.description}
                    onClick={handleClick(index)}
                    open={item.open}
                />
            ))}
        </div>
    )
}

export default Accordion