import React from "react";

import {ArrowDown} from "components/icons";

const AccordionItem = ({title, description, open, onClick}) => {
    return (
        <div className="accordion__item">
            <button onClick={onClick} className="accordion__button">
                <span className="accordion__text">{title}</span>
                <ArrowDown/>
            </button>
            {open && (
                <div className="accordion__description" dangerouslySetInnerHTML={{__html: description}}></div>
            )}
        </div>
    )
}

export default AccordionItem